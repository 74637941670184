import { Klass, u_, csl } from "@pressmedia/webappbase";
import vuwer from "@pressmedia/vuwer";

export default {
	fixBottom(vuw) {
		if( u_.isString(vuw) ) {
			vuw = this.get(vuw);
		}
		
		if( !( vuw instanceof Klass("Vuw") ) ) {
			csl.warn("fixBottom() ... arguments[0] must be vuw address or instance.");
			return false;
		}
		
		if(!vuw.$self) {
			csl.warn(`fixBottom() ... ${vuw.getAddress()} has no $element.`);
			return false;
		}
		
		// 親要素を格納
		vuw.$wrap = vuw.$self.parent();
		(vuw.$wrap.length > 1) && ( vuw.$wrap = vuw.$wrap.eq(0) );
		(vuw.$wrap.css("position") === "static") && vuw.$wrap.css("position", "relative");
		vuw.$wrap.isBorderBox = (vuw.$wrap.css("box-sizing") === "border-box");
		
		// スタイル更新関数
		vuw.setProp({
			updStyle() {
				const css = {
					"min-height": vuwer.$window.outerHeight() - 1,
					"padding-bottom": this.$self.outerHeight(true)
				};
				this.$wrap.isBorderBox || (css["min-height"] -= css["padding-bottom"]);
				this.$wrap.css(css);
				return this;
			}
		});
		
		vuw.updStyle();
		
		// for window resize event
		this.$window.on("resize", e => {
			clearTimeout(vuw._fixTimer);
			vuw._fixTimer = setTimeout(() => vuw.updStyle(), 100);
		});
		
		vuw.$self.css({
			position: "absolute",
			bottom: 0,
			left: 0
		}).addClass("fixed-bottom");
		
		return vuw;
	}
};
