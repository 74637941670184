import $ from "jquery";
import { csl, u_ } from "@pressmedia/webappbase";
import vuwer from "@pressmedia/vuwer";
import "../../assets/klass/VuwLoader";

const { app } = window;

vuwer.add("pageLoader", {
	vuwType: "loader",
	animation: "bar",
	
	selector() {
		return $("<div/>").addClass("page-loader").appendTo(document.body);
	}
});

// ** extend app **
!!app && Object.assign(app, {
	/**
	 * ローディング表示 ON
	 * @param {String} str
	 * @returns Promise
	 */
	async showLoading(str) {
		const pageLoader = vuwer.get("pageLoader");
		
		if( !pageLoader || !u_.isString(str) ) {
			return false;
		}
		
		if(pageLoader._loadingBy) {
			csl.log.gray(`  (kick showLoading ${str})`);
			return false;
		}
		
		pageLoader.isReady || await pageLoader.getReady();
		
		pageLoader._loadingBy = str;
		csl.log.green(`showLoading: ${pageLoader._loadingBy}`);
		return pageLoader.show();
	},
	
	/*----------------
	* ローディング表示 OFF
	* @return Promise
	*/
	/**
	 * ローディング表示 OFF
	 * @param {String} str
	 * @returns Promise
	 */
	hideLoading(str) {
		const pageLoader = vuwer.get("pageLoader");
		
		if( !pageLoader || !pageLoader._loadingBy || !u_.isString(str) ) {
			return false;
		}
		
		if(pageLoader._loadingBy !== str) {
			csl.log.gray(`  (kick hideLoading ${str})`);
			return false;
		}
		
		return new Promise(resolve => pageLoader.hide(() => {
			csl.log.green(`hideLoading: ${pageLoader._loadingBy}`);
			delete pageLoader._loadingBy;
			resolve();
		}));
	}
});
