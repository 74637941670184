// **** import modules ****
// pm modules
import { Klass, csl, u_ } from "@pressmedia/webappbase";
import * as google from "@pressmedia/google";


// **** process ****
csl.on("log");
csl.on("warn");

const initArgs = [];
const config = JSON.parse(APP_INFO);
if(config.name) {
	initArgs.push(config.name);
	delete config.name;
}

if( u_.isObject(config.rootDir) ) {
	const { host, hostname } = location;
	const wwwProd = `www.${config.rootDir.prod}`;
	if(hostname === wwwProd) {
		config.rootDir.prod = wwwProd;
	}
	if(hostname === "localhost") {
		config.rootDir.dev = host;
	}
	initArgs.push(config.rootDir);
	delete config.rootDir;
}

// app init
const app = Klass.new_("WebAppBase", ...initArgs);
if(!app.config.rootdir) {
	app.setRootDir(location.host);
	app._environment = "dev";
}

if(config.title) {
	app.title = config.title;
	!document.title && (document.title = config.title);
	delete config.title;
}

if(NODE_ENV === "production") {
	delete csl.colors.grey;
	csl._attachColors("log", false);
	
	const gconfig = JSON.parse(GCONFIG);
	// GA
	if( u_.isObject(gconfig.analytics) && gconfig.analytics.trackingID ) {
		google.loadGA(gconfig.analytics.trackingID, false, () => {
			csl.log.grey("loadGA -- success.");
		});
		app.gaAutoSendPageview = true;
	}
	
	// GTM
	if( u_.isObject(gconfig.tagMameger) && gconfig.tagMameger.containerID ) {
		google.loadGTM(gconfig.tagMameger.containerID, () => {
			csl.log.grey("loadGTM -- success.");
		});
	}
	
	config.google = gconfig;
}

Object.assign(app.config, config);

// export to window
Object.assign(window, {
	app,
	Klass,
	csl,
	u_
});

export default app;
export { Klass, csl, u_ };
