// **** import modules ****
import $ from "jquery";

// local modules
import vuwer from "@@assets/scripts/vuwer";


// **** process ****
vuwer.add("scrollToPageTop", {
	vuwType: "fade",
	viewSwitch: 100,
	contentHtml: `<span>ページ<br>トップへ</span>`,
	
	selector() {
		return $("<div/>").addClass("scroll-to-pageTop").attr({
			"data-visible": "0"
		}).append(
			$("<a/>").addClass("scroll-trigger").attr({
				"href": `#${document.body.id}`
			}).append( $(this.contentHtml) )
		).appendTo(vuwer.get("body").$self);
	},
	
	onReady($self) {
		!!$self && vuwer.$window.on("scroll", e => {
			clearTimeout(this._timer);
			this._timer = setTimeout(() => {
				this.setState({
					visible: vuwer.$window.scrollTop() > this.viewSwitch
				});
			}, 150);
		});
	}
});
