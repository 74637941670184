// **** import modules ****
import app, { csl } from "@@assets/scripts/app";
import vuwer from "@@assets/scripts/vuwer";
import importSlick, { defaultSlickOptions } from "@@assets/scripts/importSlick";

vuwer.add("pageHeader", {
	selector: "#page_header",
	titleSeparator: app.config.titleSeparator || " // ",
	
	async onReady($self) {
		if(!$self) {
			return false;
		}
		
		// for slider
		const $imgBox = $self.children(".img-box");
		if($imgBox.length) {
			const $slideContainer = $imgBox.children(".slide-container");
			if(
				$slideContainer.length &&
				$slideContainer.children(".slide-item").length
			) {
				await importSlick().catch(e => csl.warn("slick import error.", e));
				$slideContainer.slick({ ...defaultSlickOptions });
			}
		}
	}
});
