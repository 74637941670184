// **** import modules ****
import { u_ } from "@@assets/scripts/app";
import vuwer from "@pressmedia/vuwer";
import _onReady from "./_onReady";
import _fixBottom from "./_fixBottom";
import addImageRatioInfo from "./_addImageRatioInfo";

// **** process ****
vuwer.setProp({
	..._onReady,
	..._fixBottom,
	...addImageRatioInfo
});

vuwer.onReady( $window => $window.on("resize", e => {
	clearTimeout(vuwer._resizeTimer);
	vuwer._resizeTimer = setTimeout(() => {
		vuwer._resizeCallbacks.forEach(fn => u_.isFunction(fn) && fn());
	}, vuwer._resizeCallbackDelay);
}) );

// export to window
Object.assign(window, {
	vuwer
});

export default vuwer;
