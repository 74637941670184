// **** import modules ****
import $ from "jquery";
import moment from "moment";
import app, { csl } from "@@assets/scripts/app";
import vuwer from "@@assets/scripts/vuwer";


// **** process ****
vuwer
.add("siteFooter", {
	selector: "#site_footer",
	
	onReady($self) {
		if(!$self) {
			csl.log.gray("no siteFooter.");
			return false;
		}
		
		$self.find("a").each((i, elm) => {
			if(elm.href === app.URL) {
				$(elm).addClass("is-current");
			}
		});
	}
}, function() {
	this.add("copyright", {
		selector: "#copyright",
		
		onReady($self) {
			if(!$self) {
				return false;
			}
			
			const $toYear = $self.find(".toyear");
			if($toYear.length) {
				const nowYear = ( app.now || moment() ).format("YYYY");
				const fromYear = $self.find(".fromyear").text();
				if(fromYear === nowYear) {
					$toYear.remove();
				} else {
					$toYear.text(nowYear);
				}
			}
		}
	});
});
