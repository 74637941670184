import "slick-carousel";

function importSlick() {
	return Promise.all([
		import(/* webpackChunkName: "slick" */ "slick-carousel"),
		import(/* webpackChunkName: "slick" */ "slick-carousel/slick/slick.css"),
		import(/* webpackChunkName: "slick" */ "slick-carousel/slick/slick-theme.css")
	]);
}

const defaultSlickOptions = {
	fade: true,
	autoplay: true,
	dots: true,
	arrows: false,
	speed: 600,
	adaptiveHeight: false,
	pauseOnFocus: false,
	cssEase: "linear"
};

export default importSlick;
export { defaultSlickOptions };
