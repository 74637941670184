import $ from "jquery";

export default {
	_resizeCallbacks: [],
	_resizeCallbackDelay: 300,
	addImageRatioInfo(img) {
		const $img = $(img);
		const $frame = $(img).parent();
		
		return new Promise((resolve, reject) => {
			$img.on("load", e => {
				const attr = {};
				let imgRatio = parseFloat( $img.attr("data-ratio") );
				if( isNaN(imgRatio) )  {
					// [初回]
					const original = {
						h: $img.height(),
						w: $img.width()
					};
					
					imgRatio = original.h / original.w;
					
					Object.assign(attr, {
						"data-original-height": original.h,
						"data-original-width": original.w,
						"data-ratio": Math.round(imgRatio * 100) / 100
					});
					
					if(imgRatio === 1) {
						attr["data-original-shape"] = "square";
					} else {
						attr["data-original-shape"] = (imgRatio > 1) ? "vertically" : "horizontally";
					}
				}
				
				const frameRatio = parseFloat( $frame.innerHeight() ) / parseFloat( $frame.innerWidth() );
				// console.log($frame.get(0).className, { imgRatio, frameRatio });
				attr["data-fit-to"] = (imgRatio < frameRatio) ? "h" : "w";
				
				$img.attr(attr).addClass("is-loaded");
				resolve();
			}).on("error", reject);
			
			!!img.complete && $img.trigger("load", [true]);
			this._resizeCallbacks.push( $.fn.trigger.bind($img, "load", [true]) );
		});
	}
};
