// **** import modules ****
import $ from "jquery";

// local modules
import app, { csl, u_ } from "@@assets/scripts/app";
import vuwer from "@@assets/scripts/vuwer";


// **** process ****
vuwer.add("body", {
	selector() {
		!document.body.id && (document.body.id = "page_top");
		return $(document.body);
	},
	
	async onReady($self) {
		$self.on("click", ".change-state", e => {
			const {
				href,
				search,
				hash
			} = e.currentTarget;
			
			e.preventDefault();
			app.setUrl( href.replace(search, "").replace(hash, "") );
			vuwer.setState( u_.parseQueryString(search) );
		});
		
		// [pageName]
		app.pageName = $self.attr("data-page");
		if(!app.pageName) {
			const pInfo = u_.pathInfo(app.URL);
			app.pageName = pInfo.dirname.split("/").pop();
			(app.pageName === app.config.rootdir) && (app.pageName = "index");
			if(pInfo.filename && pInfo.filename !== "index") {
				app.pageName += `.${pInfo.filename}`;
			}
			$self.attr("data-page", app.pageName).data("page", app.pageName);
		}
		
		this
		._addDeviceInfo()
		._attachSmoothScroll()
		._attachHoverStatus()
		._attachLink4SP();
	},
	
	// [端末情報をデータ属性に付与]
	_addDeviceInfo() {
		this.$self.attr({
			"data-device": Array.isArray(app.device) ? app.device.join("-") : "",
			"data-browser": Array.isArray(app.browser) ? app.browser.join("-") : "",
			"data-pixel-ratio": window.devicePixelRatio || ""
		});
		return this;
	},
	
	// [smooth scroll]
	_attachSmoothScroll() {
		this.$self.on("click", "a", e => {
			const {
				href,
				search,
				hash
			} = e.currentTarget;
			
			// for IE
			// ページ内リンクのクリック時のみ発火
			if(hash && href.replace(hash, "") === app.URL + search) {
				e.preventDefault();
				
				const $target = $(hash);
				!!$target.length && $(document.scrollingElement).animate({
					scrollTop: Math.ceil($target.offset().top - this.smoothScrollBuffer)
				}, {
					complete: u_.isFunction(this.smoothScrollCallBack) ? () => {
						this.smoothScrollCallBack.call($target);
					} : null,
					duration: 600
				});
			}
		});
		return this;
	},
	
	smoothScrollBuffer: 0,
	
	smoothScrollCallBack() {
		csl.log.gray("scrolled.", this.offset().top);
	},
	
	// [attach hover status]
	_attachHoverStatus() {
		vuwer.$window.on("pageshow", e => {
			$(".is-hover").removeClass("is-hover");
		});
		
		this.hoverDecayTime = (app.device[0] === "sp") ? 250 : 0;
		
		this.$self
		.on("mouseenter touchstart", "a, .hover-target", e => {
			$(e.currentTarget).addClass("is-hover");
		})
		.on("mouseleave touchend", "a, .hover-target", e => {
			setTimeout(
				$.fn.removeClass.bind($(e.currentTarget), "is-hover"),
				this.hoverDecayTime
			);
		});
		
		return this;
	},
	
	/**
	* スマートフォンの場合のみリンクとなる要素
	* 動作条件:
	* 1. 「link4SP」というclassを付ける
	* 2. hrefかdata-hrefにリンク先URLを記述する
	*（例）<a class="link4SP" data-href="tel:電話番号">電話番号</a>
	*/
	_attachLink4SP() {
		this.$self.on("click", ".link4SP", e => {
			e.preventDefault();
			
			const {
				href,
				target
			} = e.currentTarget;
			
			const $this = $(e.currentTarget);
			const t = {
				href: href || $this.data("href"),
				blank: ( target || $this.data("href") ) === "_blank"
			};
			
			if(t.href && app.device[0] === "sp") {
				if(t.blank) {
					window.open(t.href);
				} else {
					location.href = t.href;
				}
			}
		});
		
		return this;
	}
});
