// **** import modules ****
import app, { csl } from "@@assets/scripts/common";


// **** process ****
app.onReady((...args) => {
	csl.log.orange(`${app.pageName} onReady`, ...args);
});

app.exec();
