import $ from "jquery";
import { csl, u_ } from "@pressmedia/webappbase";
const { app } = window;

export default {
	onReady($window) {
		!document.scrollingElement && (document.scrollingElement = document.documentElement);
		
		$window
		.on("scroll", e => {
			!this.scrollStartY && (this.scrollStartY = $window.scrollTop());
			!!this._scrollTimer && clearTimeout(this._scrollTimer);
			this._scrollTimer = setTimeout(() => {
				delete this.scrollStartY;
			}, 50);
		})
		.on("resize", (e , isTrigger) => {
			if( !isTrigger && u_.isNumber(this.scrollStartY) ) {
				csl.log.gray("resize cancel because scrolling");
				return false;
			}
		});
		
		// for pop state
		app.enablePushState && $window.on("popstate", e => {
			const state = e.originalEvent.state || {};
			!Object.keys(state).length && Object.assign(
				state,
				u_.parseQueryString(location.search)
			);
			csl.log.blue("popstate", state);
			app.setUrl();
			app._GET = Object.assign({}, state);
			state.popstate = true;
			this.setState(state);
		});
		
		// for meta
		return this._setMeta();
	},
	
	_setMeta() {
		if(!this.$window) {
			return this;
		}
		
		const $head = $(document.head);
		const $description = $head.children("meta[name='description']");
		const $keywords = $head.children("meta[name='keywords']");
		
		let $canonical = $head.children("link[rel='canonical']");
		if(!$canonical.length) {
			$canonical = $("<link/>").attr({
				rel: "canonical",
				href: app.URL
			}).insertBefore( $head.children("link").eq(0) );
		}
		
		let $robots = $head.children("meta[name='robots']");
		let robotsContent = [];
		if(!$robots.length) {
			$robots = $("<meta/>").attr({
				name: "robots",
			}).insertAfter( $head.children("meta").eq(-1) );
			
			robotsContent.push("index");
			robotsContent.push("follow");
		}
		
		if( u_.isObject(app.config.page) ) {
			robotsContent = [
				!app.config.page.noindex ? "index" : "noindex",
				!app.config.page.nofollow ? "follow" : "nofollow"
			];
		}
		
		!!robotsContent.length && $robots.attr({
			content: robotsContent.join(",")
		});
		
		this.$head = $head;
		this.meta = {
			$description,
			$keywords,
			$canonical,
			$robots
		}
		
		return this;
	}
};
