// **** import modules ****
// jQuery
import $ from "jquery";

// moment
import * as moment from "moment";
import "moment/locale/ja";
moment.locale("ja");

// export to window
Object.assign(window, { $, moment });


// WebAppBase
import app, { Klass, csl, u_ } from "./app";
import "./history";
import killhistoryBackOnBackspace4IE from "./history/killhistoryBackOnBackspace4IE";
import "@pressmedia/webappbase-plugin-popup";
import "@pressmedia/webappbase-plugin-exec";

// components
import vuwer from "./vuwer";
import "@@components/body";
import "@@components/siteHeader";
import "@@components/siteFooter";
import "@@components/pageLoader";
import "@@components/pageHeader";
import "@@components/pageMain";
import "@@components/scrollToPageTop";


// **** process ****
Object.assign(app, {
	// [on execute]
	async onExec(err, res) {
		// [Google Analytics] pageviewイベントを送信
		!!this.gaAutoSendPageview && this.sendGaPageview();
		
		// ポップアップ
		!!this.popup && this.popup.init(this);
		
		if(err) {
			csl.warn("exec", "エラーのため処理を終了します", err);
			return false;
		}
		
		!this.now && ( this.now = moment() );
		
		// IEのbackspaceキーでhistory.back()する機能を切る
		if(this.browser[0] === "ie") {
			killhistoryBackOnBackspace4IE();
		}
		
		if(NODE_ENV !== "production") {
			await Promise.all([
				import(/* webpackChunkName: "_kousei_" */ "@@assets/_kousei/auth"),
				import(/* webpackChunkName: "_kousei_" */ "@@assets/_kousei/form"),
				import(/* webpackChunkName: "_kousei_" */ "@@assets/_kousei/auth.scss")
			]);
		}
		
		// コンポーネントの準備
		const resVuwer = await vuwer.getReady();
		csl.log.blue("**** vuwer is ready. ****", resVuwer);
		
		// 初期状態をvuwerへ反映
		const state = Object.assign({}, app._GET);
		return vuwer.setState(state);
	},
	
	// [after execute]
	async afterExec() {
		await new Promise((resolve, reject) => {
			// 画像部分の描画時間を考慮して、処理を遅らせる
			vuwer.isReady ? setTimeout(resolve, 100) : reject();
		});
		
		// フッタを最下部にフィックス
		vuwer.fixBottom("siteFooter");
		
		csl.log.blue("**** app is ready. ****");
		
		await new Promise(resolve => setTimeout(resolve, 0));
		vuwer.$window.trigger("resize", [true]).trigger("scroll", [true]);
		csl.log.blue(
			`** page initialized: ${app.pageName || "{no pageName}"} **`
		);
	}
});

export default app;
export { Klass, csl, u_, vuwer };
