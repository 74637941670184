// **** import modules ****
// jquery
import $ from "jquery";

// pm modules
import { Klass, u_ } from "@pressmedia/webappbase";
import vuwer from "@pressmedia/vuwer";


// **** process ****
// [loader用Vuwの定義]
// prototype継承して登録
vuwer.appendKlass("VuwLoader", {
	// "circle" | "bar"
	animation: "circle",
	
	_initialize() {
		if( Klass("VuwLoader").parent._initialize.apply(this, arguments) ) {
			// [push onReady callback]
			this.onReady($self => {
				if($self) {
					$self.addClass("vuw-loader");
					this._createProgressBox();
				}
			});
			return this;
		} else {
			return false;
		}
	},
	
	_createProgressBox() {
		const $box = $("<div/>").addClass("progress-box").attr({
			"data-animation": this.animation
		});
		
		const $circle = $("<div/>").addClass("progress");
		
		for(let i = 0; i < 8; i++) {
			$circle.clone().appendTo($box);
		}
		
		this.$self.empty().append($box);
		
		return this;
	},
	
	show(cb) {
		this.setState("visible", true).then( u_.isFunction(cb) && cb.bind(this) );
		return this;
	},
	
	hide(cb) {
		this.setState("visible", false).then( u_.isFunction(cb) && cb.bind(this) );
		return this;
	}
}, "VuwFade");
